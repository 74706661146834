new Vue({
  el: '#refresh',
  data: function() {
    return {
      isRefreshing: false,
    };
  },
  methods: {
    getBaseUrl() {
      const url = new URL(window.location.href);
      return url.origin + url.pathname;
    },
    refreshPage() {
      setTimeout(() => {
        window.location.href = this.getBaseUrl();
      }, 60000);  
    },

    toggleRefresh() {
      if (this.isRefreshing === false) {
        this.isRefreshing = true;
        localStorage.setItem('refresh', true);
        this.refreshPage();
      } else {
        this.isRefreshing = false;
        localStorage.removeItem('refresh');
      }
    }
  },
  created() {
    if (localStorage.getItem('refresh')) {
      this.isRefreshing = true;
      this.refreshPage();
    }
  }
});